import React from "react"
import {Link} from "gatsby";

const Pagination = ({ path, currentPage, numPages }) => {
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = (currentPage - 1) === 1 ? '' : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()

  path = path.substr(0, path.split('/', 2).join('/').length)

  return (
    <>
      {numPages > 1 && (
        <nav className="pagination is-centered" role="navigation" aria-label="pagination">
          {!isFirst && (
            <Link className="pagination-previous button is-rounded" to={`${path}/${prevPage}`} rel="prev">
              ← Page précédente
            </Link>
          )}
          <div className="pagination-list">
            {currentPage} / {numPages}
          </div>
          {!isLast && (
            <Link className="pagination-next button is-rounded" to={`${path}/${nextPage}`} rel="next">
              Page suivante →
            </Link>
          )}
        </nav>
      )}
    </>
  )
}

export default Pagination