import React from "react"
import {graphql} from 'gatsby'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import Layout from "../components/layout"
import SEO from "../components/seo"
import ShowsListItem from '../components/shows-list-item'
import Pagination from "../components/pagination";

const ShowsList = ({ pageContext, data, location }) => {
  const {
    breadcrumb: { crumbs },
    currentPage,
    numPages
  } = pageContext

  return (
    <Layout>
      <SEO
          title="Nos émissions"
          description="Retrouvez la liste de nos émissions."
      />
      <div id="shows-list" className="container list-page">
        <section className="section page-header">
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" > "
            crumbLabel="Émissions"
          />

          <h1 className="title is-1">Nos émissions</h1>
        </section>

        <section className="section page-content">
          <div className="columns is-multiline">
            {data.allNodeEmission.edges.map(({ node }, index) => (
              <ShowsListItem node={node} key={index} columnType={4} textLimit={120} cssTruncate={false}/>
            ))}
          </div>

          <Pagination
            path={location.pathname}
            currentPage={currentPage}
            numPages={numPages}
          />
        </section>
      </div>
    </Layout>
  )
}

export default ShowsList

export const query = graphql`
  query emissionListQuery($skip: Int!, $limit: Int!) {
    allNodeEmission(
      filter: {status: {eq: true}}, 
      sort: {fields: field_poids, order: DESC}
      limit: $limit, 
      skip: $skip
    ) {
      edges {
        node {
          title
          id
          created(formatString: "DD MMMM YYYY", locale: "fr")
          body {
            summary
            value
          }
          path {
            alias
          }
          relationships {
            image: field_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 670, maxHeight: 400, quality: 90) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
